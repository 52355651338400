import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { FEATURES } from '@wsui/core/models/ActiveCompany';
import useActiveCompany from 'app/hooks/useActiveCompany';
import useCapabilities from 'app/hooks/useCapabilities';
import fetchBuyerBanner from 'app/fetch/fetchBuyerBanner';
import Tabs from 'app/utils/Tabs';

export default function useTabs ({ isDesktop } = {}) {
    const { activeCompany, activeCompanyId } = useActiveCompany();
    const { reviewSubmittedOnboarding } = useCapabilities();
    const { data } = useQuery(['buyerBanner', activeCompanyId], fetchBuyerBanner, {
        enabled: activeCompany?.isBuyer || false,
    });

    return useMemo(() => {
        if (!activeCompany) {
            return [];
        }

        const topRoutes = isDesktop
            ? [...Tabs.topRoutes[Tabs.profileName(activeCompany)]]
            : [...Tabs.mobileRoutes[Tabs.profileName(activeCompany)]];

        if (activeCompany?.feature(FEATURES.newCustomerOnboarding)
            && ['Vendor', 'StandaloneNcf'].includes(Tabs.profileName(activeCompany))
            && reviewSubmittedOnboarding?.canView) {
            topRoutes.splice(topRoutes.indexOf(Tabs.TAB_IDS.Autopay) + 1, 0, Tabs.TAB_IDS.NewCustomerForm);
        }

        if (isDesktop && activeCompany.feature(FEATURES.salesDemoBanner) && activeCompany.isBuyer) {
            topRoutes.push('BuyerUpsell');
        }

        const staticTabs = topRoutes.map(i => {
            if (i === Tabs.TAB_IDS.AutopayOut && activeCompany.isBuyer) {
                return {
                    ...Tabs.tabs[i],
                    indicator: data?.hasAutopayProposal,
                };
            }

            return Tabs.tabs[i];
        });
        const dynamicTabs = [];
        if (activeCompany.isBuyer && activeCompany?.feature(FEATURES.buyerNavLinks)) {
            activeCompany?.feature(FEATURES.buyerNavLinks).forEach(({text, link, icon}) => {
                dynamicTabs.push({
                    tabName: text,
                    label: text,
                    icon,
                    href: link,
                });
            });
        } else if (activeCompany.isSeller && activeCompany?.feature(FEATURES.sellerNavLinks)) {
            activeCompany?.feature(FEATURES.sellerNavLinks).forEach(({text, link, icon}) => {
                dynamicTabs.push({
                    tabName: text,
                    label: text,
                    icon,
                    href: link,
                });
            });
        }

        return [...staticTabs, ...dynamicTabs];

    }, [isDesktop, activeCompany, data, reviewSubmittedOnboarding]);
}
